<template>
	<main class="auth-layout container">
		<div class="auth-form__logo mb-40">
			<Logo />

			<span class="p7">Агрохелпер</span>
		</div>

		<slot />
	</main>
</template>

<style lang="scss">
	.auth-layout {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.auth-form__logo {
		display: grid;
		gap: 12px;
		align-items: center;
		justify-content: center;
	}
</style>
